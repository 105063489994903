import React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
const blackLogo = require('./logos/navbar_logo.png')

export function ScrollToTopOnMount() {
  useEffect(() => {
    document.querySelector('#root').scrollTo(0,0)
  }, []);

  return null;
}

const LogoImage = styled.img`
  display: none;
  height: 3rem;
  width: 3rem;
  margin: 0.25rem;
  position: absolute;
  top: 1%;
  right: 2.5%;
  @media(max-width: 499px) {
    display: initial;
  }
`

export const MobileLogo = () => <Link to="/"><LogoImage src={blackLogo} /></Link>

export const GlobalChanges = createGlobalStyle`
  #root {
    overflow: scroll;
  }
`

export const BenefitsContainer = styled.div`
  margin-top: 7.5rem;
  @media(max-width: 499px) {
    margin-top: 4rem;
    margin-bottom: -5rem;
  }
`
export const Container = styled.div`
  margin-top: 7.5rem;
  @media(max-width: 499px) {
    margin-top: 4rem;
  }
`

// What does MBM mean? This is used to wrap all but the contact page...
export const ContainerMBM = styled.div`
  margin-top: 7.5rem;
  @media(max-width: 499px) {
    margin-bottom: -4rem;
    margin-top: 4rem;
  }
`

export const SmallPageHeadlineTopAbout = styled.div`
  font-family: 'Cormorant Garamond';
  font-size: 3rem;
  text-align: center;
  margin: 0.75rem 0 0.25rem 0;
  @media(max-width: 499px) {
    font-size: 1.75rem;
  }
`

export const SmallPageHeadlineBottomAbout = styled.div`
  font-family: 'Cormorant Garamond';
  font-size: 3rem;
  text-align: center;
  margin: 0.25rem 0 1.25rem 0;
  @media(max-width: 499px) {
    font-size: 2rem;
  }
`

export const SmallPageHeadlineTop = styled.div`
  font-family: 'Cormorant Garamond';
  font-size: 3rem;
  text-align: center;
  margin: 0.75rem 0 0.25rem 0;
  @media(max-width: 499px) {
    font-size: 2rem;
  }
`

export const SmallPageHeadlineBottom = styled.div`
  font-family: 'Cormorant Garamond';
  font-size: 3rem;
  text-align: center;
  margin: 0.25rem 0 2rem 0;
  @media(max-width: 499px) {
    font-size: 2rem;
  }
`

export const LargePageHeadline = styled.div`
  font-family: 'Cormorant Garamond';
  font-size: 3rem;
  text-align: center;
  margin: 1rem 0 0.25rem 0;
  @media(max-width: 499px) {
    font-size: 2rem;
  }
`

export const OuterContainerServices = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 3rem;

  @media(max-width: 499px) {
    margin-bottom: 0;
  }
`

export const FlexContainerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 3rem;
`

export const FlexContainerContainerBottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const FlexContainerContainerContact = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const FlexContainerContainerLargeMargin = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 3rem;
  @media(max-width: 499px) {
    margin-bottom: 0;
  }
`

export const FlexContainerLeftText = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 1rem 0 2rem 0;
  max-width: 75rem;
  min-height: 42rem;

  @media(max-width: 499px) {
    flex-direction: column;
    padding-bottom: 0;
    width: 100%;
  }
`

export const FlexContainerLeftTextBottom = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 1rem 0 2rem 0;
  max-width: 75rem;
  max-height: 44rem;

  @media(max-width: 75rem) {
    max-height: initial;
  }

  @media(max-width: 499px) {
    flex-direction: column;
    padding-bottom: 0;
    width: 100%;
    max-height: initial;
  }
`

export const FlexContainerLeftTextContact = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 1rem 0 2rem 0;
  max-width: 75rem;

  @media(max-width: 499px) {
    flex-direction: column;
    padding-bottom: 0;
    width: 100%;
  }
`

export const FlexContainerRightText = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0 2rem 0;
  max-width: 75rem;
  max-height: 49rem;

  @media(max-width: 75rem) {
    max-height: initial;
  }

  @media(max-width: 499px) {
    flex-direction: column;
    padding-bottom: 0;
    width: 100%;
    max-height: 49rem;
    padding: 0rem 0 2rem 0;
  }
`

export const FlexContainerRightTextLarge = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0 2rem 0;
  max-width: 75rem;
  max-height: 49rem;

  @media(max-width: 75rem) {
    max-height: initial;
  }

  @media(max-width: 499px) {
    flex-direction: column;
    padding-bottom: 0;
    width: 100%;
    max-height: 49rem;
  }
`
export const ImageDivMeetKG = styled.div`
  flex: 1;
  margin-bottom: 3rem;
  width: 60%;

  @media(max-width: 499px) {
    width: 100%;
    height: auto;
    margin-bottom: 0;
    padding-top: 9rem;
  }

  @media(max-width: 400px) {
    width: 100%;
    height: auto;
    margin-bottom: 0;
    padding-top: 5rem;
  }
`

export const ImageDiv = styled.div`
  flex: 1;
  margin-bottom: 3rem;
  width: 60%;

  @media(max-width: 499px) {
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }
`

export const ImageDivCabana = styled.div`
  flex: 1;
  margin-bottom: 3rem;
  width: 60%;

  @media(max-width: 499px) {
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }
`

export const ImageDivFeeStruct = styled.div`
  flex: 1;
  margin-bottom: 3rem;
  width: 60%;

  @media(max-width: 499px) {
    width: 100%;
    margin-bottom: 0;
    padding-top: 10rem;
    box-shadow: inset 0 -1rem #737373;
  }

  @media(max-width: 400px) {
    width: 100%;
    margin-bottom: 0;
    padding-top: 17rem;
    box-shadow: inset 0 -1rem #737373;
  }
`

export const ImageDivItinDes = styled.div`
  flex: 1;
  margin-bottom: 3rem;
  width: 60%;

  @media(max-width: 499px) {
    width: 100%;
    height: auto;
    margin-bottom: 0;
    padding-top: 11rem;
  }

  @media(max-width: 400px) {
    width: 100%;
    height: auto;
    margin-bottom: 0;
    padding-top: 23rem;
  }
`

export const ImageDivBottom = styled.div`
  flex: 1;
  margin-bottom: 3rem;
  width: 60%;

  @media(max-width: 499px) {
    width: 100%;
    margin-bottom: 0;
  }
`

export const ContactImageDiv = styled.div`
  flex: 1;
  margin-bottom: 2rem;
  width: 60%;

  @media(max-width: 499px) {
    width: 100%;
    margin-bottom: 0;
    max-height: 20rem;
  }
`

export const CoverImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const FeeStructureImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media(max-width: 499px) {
    max-height: 12rem;
  }
`

export const CoverImageMaxSize = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media(max-width: 499px) {
    max-height: 12rem;
  }
`

export const CoverImageMediaQuery = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 100% 0%;
  @media(max-width: 400px) {
    height: 20rem;
  }
`

export const CoverImageMediaQueryHeadshot = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 100% 0%;
  @media(max-width: 400px) {
    height: 20rem;
  }
`

const WirdsWrapper = styled.div`
  margin: 2rem -2rem 0 0;
  width: 45%;
  background-color: #737373;
  color: white;
  padding: 0 2rem 0 2rem;
  border: 2px solid white;

  @media(max-width: 499px) {
    flex-direction: column-reverse;
    padding: 0;
    width: 100%;
    margin: 0;
    border: none;
  }
`

const WirdsWrapperTwo = styled.div`
  margin: 0 0 2rem 0;
  @media(max-width: 499px) {
    margin: 0 1rem 1rem 1rem;
  }
`

const WirdsWrapperTwoBottomMarginDesktop = styled.div`
  margin: 0 0 2rem 0;
  @media(max-width: 499px) {
    margin: 0 1rem 1rem 1rem;
  }
`

const WirdsWrapperWhite = styled.div`
  margin: 2rem 0 0 -2rem;
  width: 40%;
  background-color: white;
  color: #737373;
  padding: 2rem 1.5rem 1.5rem 2rem;
  border: 2px solid #737373;

  @media(max-width: 499px) {
    flex-direction: column-reverse;
    padding: 0;
    width: 100%;
    margin: 0 0 0 0;
    border: none;
    color: black;
  }
`

const WirdsWrapperWhiteLarge = styled.div`
  margin: 2rem 0 0 -2rem;
  width: 60%;
  background-color: white;
  color: #737373;
  padding: 2rem 1.5rem 1.5rem 2rem;
  border: 2px solid #737373;

  @media(max-width: 499px) {
    flex-direction: column-reverse;
    padding: 0;
    width: 100%;
    margin: 0;
    border: none;
    color: black;
  }
`

const WordsDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const WirdsWrapperBottom = styled.div`
  margin: 2rem -2rem 5rem 0;
  width: 40%;
  background-color: #737373;
  color: white;
  padding: 0 2rem 0 2rem;
  border: 2px solid white;

  @media(max-width: 499px) {
    flex-direction: column-reverse;
    padding: 0;
    width: 100%;
    margin: 0 0 0 0;
    border: none;
  }
`

export const GrayWordsContainer = (props) =>
  <WirdsWrapper>
    <WirdsWrapperTwo>
      <WordsDiv>
        {props.children}
      </WordsDiv>
    </WirdsWrapperTwo>
  </WirdsWrapper>

export const GrayWordsContainerBottomMarginDesktop = (props) =>
  <WirdsWrapper>
    <WirdsWrapperTwoBottomMarginDesktop>
      <WordsDiv>
        {props.children}
      </WordsDiv>
    </WirdsWrapperTwoBottomMarginDesktop>
  </WirdsWrapper>

export const WhiteWordsContainer = (props) =>
  <WirdsWrapperWhite>
    <WirdsWrapperTwo>
      <WordsDiv>
        {props.children}
      </WordsDiv>
    </WirdsWrapperTwo>
  </WirdsWrapperWhite>

export const WhiteWordsContainerLarge = (props) =>
  <WirdsWrapperWhiteLarge>
    <WirdsWrapperTwo>
      <WordsDiv>
        {props.children}
      </WordsDiv>
    </WirdsWrapperTwo>
  </WirdsWrapperWhiteLarge>

export const SmallGrayWordsContainer = (props) =>
  <WirdsWrapperBottom>
    <WirdsWrapperTwo>
      <WordsDiv>
        {props.children}
      </WordsDiv>
    </WirdsWrapperTwo>
  </WirdsWrapperBottom>



export const SectionHeadingTop = styled.div`
  text-align: center;
  font-family: 'Cormorant Garamond';
  font-weight: bold;
  font-size: 2.5rem;
  margin: 2rem 2rem 0rem 2rem;

  @media(max-width: 499px) {
    margin: 1rem 2rem 0rem 2rem;
    font-size: 2rem;
  }
`

export const SectionHeading = styled.div`
  text-align: center;
  font-family: 'Cormorant Garamond';
  font-weight: bold;
  font-size: 3rem;
  margin: 2rem 2rem 0rem 2rem;

  @media(max-width: 499px) {
    margin: 1rem 2rem 0rem 2rem;
    font-size: 2rem;
  }
`

export const SectionHeadingSmaller = styled.div`
  text-align: center;
  font-family: 'Cormorant Garamond';
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0.5rem 2rem 0.25rem 2rem;

  @media(max-width: 499px) {
    margin: 1rem 2rem 0rem 2rem;
    font-size: 1.6rem;
  }
`

export const SectionHeadingSmall = styled.div`
  text-align: center;
  font-family: 'Cormorant Garamond';
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0rem 2rem 0rem 2rem;

  @media(max-width: 499px) {
    margin: 1rem 2rem 0rem 2rem;
    font-size: 2rem;
  }
`
export const SectionSubHeading =  styled.div`
  text-align: center;
  font-family: 'Cormorant Garamond';
  font-style: italic;
  font-size: 1rem;
  font-weight: 200;
  margin-bottom: 1rem;
`

export const SectionSubHeadingTwo =  styled.div`
  text-align: center;
  font-family: 'Cormorant Garamond';
  font-style: italic;
  font-size: 1rem;
  font-weight: 200;
`

export const Detail = styled.div`
  margin: 0.5rem;
`
export const Details = styled.span`
  font-family: 'Barlow';
  font-size: 1rem;
`

export const DetailsHeavy = styled.span`
  font-family: 'Barlow';
  font-size: 1rem;
  font-weight: bold;

  @media(max-width: 499px) {
    font-weight: normal;
  }
`

export const Label = styled.span`
  font-family: 'Cormorant Garamond';
  font-weight: bold;
  font-size: 1.25rem;
`

const SeparatorSpan = styled.span`
  margin: 0 0.5rem 0 0.5rem;
`

export const Separator = () => <SeparatorSpan>|</SeparatorSpan>
