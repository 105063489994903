import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  FaInstagram,
} from "react-icons/fa";
import { IconContext } from "react-icons";
import BlackBurger from './burger/black-burger';
import { MobileMenu } from './menu'
import { createGlobalStyle } from 'styled-components';
import { MobileLogo } from './components';

const NavStyling = createGlobalStyle`
  .showthenavdog {
    @media(max-width: 499px) {
      transition: top 0.6s;
    }
  }

  .hidethenavdog {
    @media(max-width: 499px) {
      display: none;
    }
  }
`
const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 7.5rem;
  @media(max-width: 499px) {
    height: 3rem;
  }
`

const LeftSide = styled.div`
`

const RightSide = styled.div`
  display: flex;
  @media(max-width: 499px) {
    display: none;
  }
`

const blackLogo = require('./logos/navbar_logo.png')
const LogoImage = styled.img`
  height: 7rem;
  width: 7rem;
  margin: 0.25rem;
  @media(max-width: 499px) {
    display: none;
  }
`

const Logo = () => <LogoImage src={blackLogo} />

const LinkDiv = styled.div`
  padding: 2.5rem 1rem 2.5rem 1rem;
  font-size: 1.5rem;
  font-family: Barlow;
  a {
    text-decoration: none;
    color: black;
  }
`

const WhiteInstaLink = styled.a`
  text-decoration: none;
  color: white;
  margin: 2.75rem 1rem 3rem 1rem;
`


const AboutLink = () =>
  <LinkDiv>
    <Link to="/about">About</Link>
  </LinkDiv>

const ContactLink = () =>
  <LinkDiv>
    <Link to="/contact">Contact</Link>
  </LinkDiv>

const BenefitsLink = () =>
  <LinkDiv>
    <Link to="/benefits">Benefits</Link>
  </LinkDiv>

const BookAHotelLink = () =>
  <LinkDiv>
    <Link to="/book-a-hotel">Book A Hotel</Link>
  </LinkDiv>

const HowItWorksLink = () =>
  <LinkDiv>
    <Link to="/how-it-works">How It Works</Link>
  </LinkDiv>

const InstaLink = styled.a`
  text-decoration: none;
  color: black;
  margin: 2.5rem 1rem 3rem 1rem;
`

const OtherInstaLink = styled.a`
  text-decoration: none;
  color: black;
`

export const OtherInstagramLink = () =>
  <OtherInstaLink href="https://www.instagram.com/kgtravelclub" target="_blank">
    <IconContext.Provider value={{ size: "2em" }}>
      <FaInstagram />
    </IconContext.Provider>
  </OtherInstaLink>

export const InstagramLink = () =>
  <InstaLink href="https://www.instagram.com/kgtravelclub" target="_blank">
    <IconContext.Provider value={{ size: "2em" }}>
      <FaInstagram />
    </IconContext.Provider>
  </InstaLink>

export const WhiteInstagramLink = () =>
  <WhiteInstaLink href="https://www.instagram.com/kgtravelclub" target="_blank">
    <IconContext.Provider value={{ size: "2em" }}>
      <FaInstagram />
    </IconContext.Provider>
  </WhiteInstaLink>

const OutsideNavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 7.5rem;
  @media(max-width: 499px) {
    min-height: 4rem;
  }
`
const NavContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  min-height: 7.5rem;
`
const OkDiv = styled.div`
  @media(min-width: 499px) {
    display: none;
  }
`

const FixedNav = styled.div`
  position: fixed;
  min-height: 7.5rem;
  height: 7.5rem;
  width: 100%;
  background: white;
  z-index: 2;
  @media(max-width: 499px) {
    min-height: 4rem;
    height: 4rem;
  }
`

class Navigation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: true,
      lastPos: 0,
      open: false,
    }

    this.setOpen = this.setOpen.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  setOpen() {
    this.setState({
      open: !this.state.open
    })
  }

  onScroll(e) {
    const rawPos = e.srcElement.scrollTop;
    const pos = rawPos < 0 ? 0 : rawPos;
    const last = this.state.lastPos

    this.setState({
      visible: last >= pos,
      lastPos: pos,
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true)
  }

  render() {
    return (
      <>
        <NavStyling />
        <FixedNav className={`${this.state.visible ? 'showthenavdog': 'hidethenavdog' }`}>
          <OutsideNavContainer>
            <NavContainer>
              <Bar>
                <LeftSide>
                  <Link to="/">
                    <Logo />
                  </Link>
                  <OkDiv>
                    <BlackBurger open={this.state.open} setOpen={this.setOpen}/>
                    <MobileMenu open={this.state.open} setOpen={this.setOpen}/>
                    <MobileLogo />
                  </OkDiv>
                </LeftSide>
                <RightSide>
                  <AboutLink />
                  <BenefitsLink />
                  <HowItWorksLink />
                  <BookAHotelLink />
                  <ContactLink />
                  <InstagramLink />
                </RightSide>
              </Bar>
            </NavContainer>
          </OutsideNavContainer>
        </FixedNav>
      </>
    )
  }
}

export default Navigation;
