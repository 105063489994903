import styled from 'styled-components';
import React from 'react';
import { IconContext } from "react-icons";
import {
  FaInstagram,
} from "react-icons/fa";

import { Link } from 'react-router-dom';

const OtherInstaLink = styled.a`
  text-decoration: none;
  color: black;
`

const OtherInstagramLink = () =>
  <OtherInstaLink href="https://www.instagram.com/kgtravelclub" target="_blank">
    <IconContext.Provider value={{ size: "2em" }}>
      <FaInstagram />
    </IconContext.Provider>
  </OtherInstaLink>

const StyledMobileMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.90);
  height: 100vh;
  text-align: left;
  padding: 12rem 2rem 2rem 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  width: 100%;
  overflow: hidden;
  @media(max-width: 499px) {
    padding: 8rem 2rem 2rem 0;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: center;
    font-family: Barlow;

    @media (max-width: 480px) {
      font-size: 1.5rem;
    }

    &:hover {
      color: #d3d3d3;
    }
  }
`;

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.75);
  height: 100vh;
  text-align: left;
  padding: 12rem 2rem 2rem 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  width: 100%;
  overflow: hidden;
  @media(max-width: 499px) {
    padding: 8rem 2rem 2rem 0;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 1.5rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: center;
    font-family: Barlow;

    @media (max-width: 480px) {
      font-size: 1.5rem;
    }

    &:hover {
      color: #d3d3d3;
    }
  }
`;

const PlaceHolder = styled.div`
  height: 2rem;
`

const Menu = ({open, setOpen}) => {
  return (
    <StyledMenu open={open}>
      <PlaceHolder />
      <Link to="/about" onClick={() => setOpen(!open)}>About</Link>
      <Link to="/benefits" onClick={() => setOpen(!open)}>Benefits</Link>
      <Link to="/how-it-works" onClick={() => setOpen(!open)}>How It Works</Link>
      <Link to="/book-a-hotel" onClick={() => setOpen(!open)}>Book A Hotel</Link>
      <Link to="/contact" onClick={() => setOpen(!open)}>Contact</Link>
      <OtherInstagramLink />
    </StyledMenu>
  )
}
export default Menu;

export const MobileMenu = ({open, setOpen}) => {
  return (
    <StyledMobileMenu open={open}>
      <PlaceHolder />
      <Link to="/about" onClick={() => setOpen(!open)}>About</Link>
      <Link to="/benefits" onClick={() => setOpen(!open)}>Benefits</Link>
      <Link to="/how-it-works" onClick={() => setOpen(!open)}>How It Works</Link>
      <Link to="/contact" onClick={() => setOpen(!open)}>Contact</Link>
      <OtherInstagramLink />
    </StyledMobileMenu>
  )
}
