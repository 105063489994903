import React from "react";
import styled from "styled-components";
import {
    FaCircle,
    FaRegCircle,
} from 'react-icons/fa'

const GRAY = '#737373'
const WHITE = 'white'

const getSide = (side) => {
    switch (side) {
        case "right-extra-small": return "59 / 100";
        case "right-small": return "55 / 101";
        case "right-large": return "43 / 101";
        case "right-extra-large": return "34 / 101";
        case "left-extra-small": return "1 / 38"
        case "left-small": return "1 / 47";
        case "left-large": return "1 / 58";
        case "left-extra-large": return "1 /62";
        default: return;
    }
}

const PageHeadline = styled.div`
  font-family: 'Cormorant Garamond';
  font-size: 3rem;
  text-align: center;
  margin: 0.75rem 0 0.25rem 0;
  @media(max-width: 499px) {
    font-size: 1.75rem;
    padding: 1rem 0 2rem 0;
  }
`

// only used in book-a-hotel
export const PageHeadlineSmall = styled.div`
  font-family: 'Cormorant Garamond';
  font-size: 1rem;
  text-align: center;
  margin: 0;
`

const FullRow = styled.div`
    display: inline-block;
    width: 100%;
    margin: 3rem auto 0 auto;
    @media(max-width: 499px) {
        margin: 0;
        display: block;
    }
`

const RowContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(100,1fr);
    margin: auto;
    max-width: 75rem;
    @media(max-width: 499px) {
        display: block;
        max-width: 100%;
        grid-template-columns: none;
    }
`

const Row = (props) =>
    <FullRow>
        <RowContainer>
            {props.children}
        </RowContainer>
    </FullRow>

const FullGrayRow = styled.div`
    display: inline-block;
    width: 100%;
    margin: 3rem auto 0 auto;
    background-color: ${GRAY};
    color: ${WHITE};
    @media(max-width: 499px) {
        margin: 0;
        display: block;
    }
`

const GrayRow = (props) =>
    <FullGrayRow>
        {props.children}
    </FullGrayRow>



const FullTextSection = styled.div`
    background-color: ${WHITE};
    color: ${GRAY};
    margin-top: 1rem;
    z-index: 1;
    border: none;
    grid-column: none;
    grid-row: none;
    display: block;
    margin-top: 0;
`

const GrayTextSection = styled.div`
    background-color: ${GRAY};
    border: solid 2px ${WHITE};
    color: ${WHITE};
    margin-top: 1rem;
    grid-column: ${({ side }) => getSide(side)};
    grid-row: 1;
    z-index: 1;
    @media(max-width: 499px) {
        border: none;
        display: block;
        grid-column: none;
        grid-row: none;
        margin-top: 0;
    }
`
const WhiteTextSection = styled.div`
    background-color: ${WHITE};
    border: solid 2px ${GRAY};
    color: ${GRAY};
    margin-top: 1rem;
    grid-column: ${({ side }) => getSide(side)};
    grid-row: 1;
    z-index: 1;
    @media(max-width: 499px) {
        border: none;
        grid-column: none;
        grid-row: none;
        display: block;
        margin-top: 0;
    }
`

const SectionHeadline = styled.div`
    font-family: 'Cormorant Garamond';
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1.5rem auto;
    text-align: center;
    @media(max-width: 499px) {
        padding-top: 3rem;
        margin: 0 auto 1.5rem auto;
    }
`
export const NoBulletedList = styled.ul`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: none;
  margin: 1rem auto 2rem auto;
  padding: 0;
  width: 80%;
  @media(max-width: 499px) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
      margin: none;
      width: 100%o;
      list-style-type: none;
      text-align: center;
      margin: 0 auto;
      padding-bottom: 3rem;
  }
`

export const NoBulletedListItem = styled.li`
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
`

const BulletedList = styled.ul`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-position: inside;
  margin: 1rem auto 2rem auto;
  padding: 0;
  width: 80%;
  @media(max-width: 499px) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
      margin: none;
      width: 100%o;
      list-style-type: none;
      text-align: center;
      margin: 0 auto;
      padding-bottom: 3rem;
  }
`

const BulletedListItem = styled.li`
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
`

const UndecoratedList = styled.ul`
    list-style-type: none;
    padding: 0;
    width: 80%;
    margin: 1rem auto 2rem auto;
    @media(max-width: 499px) {
        margin: 0 auto;
        padding-bottom: 3rem;
    }
`

const ImageSection = styled.div`
    background-image: url(${props => props.image});
    background-size: cover;
    grid-column: ${({ side }) => getSide(side)};
    grid-row: 1;
    margin-bottom: 1rem;
    @media(max-width: 499px) {
        height: ${({ mobileHeight }) => mobileHeight};
        grid-column: none;
        grid-row: none;
        margin: 0;
        display: block;
    }
`

const ResizingImageSection = styled.div`
    background-image: url(${props => props.image});
    background-size: cover;
    grid-column: ${({ side }) => getSide(side)};
    grid-row: 1;
    margin-bottom: 1rem;
    @media(max-width: 499px) {
        height: ${({ mobileHeight }) => mobileHeight};
        grid-column: none;
        grid-row: none;
        margin: 0;
        display: block;
        background-size: 100%;
        background-position: bottom;
    }
`

const Title = styled.span`
    font-family: 'Cormorant Garamond';
    font-weight: bold;
    font-size: 1.25rem;
`

const SeparatorSpan = styled.span`
    margin: 0 0.5rem;
`

const Separator = () => <SeparatorSpan>|</SeparatorSpan>

const Description = styled.span`
    font-family: 'Barlow';
`

const ListItemTitleDescriptionLI = styled.li`
    margin: 0.5rem 0.5rem 1rem 0.5rem;
`

const ListItemTitleDescription = (props) => {
    if (props.hasLink) {
        return (
            <ListItemTitleDescriptionLI>
                <Title>
                    {props.summary}
                </Title>
                <Separator />
                <Description>
                    {props.description[0]}
                    {props.hasLink}
                    {props.description[1]}
                </Description>
            </ListItemTitleDescriptionLI>
        )
    }

    return (
    <ListItemTitleDescriptionLI>
        <Title>
            {props.summary}
        </Title>
        <Separator />
        <Description>
            {props.description}
        </Description>
    </ListItemTitleDescriptionLI>
    )
}


const PlainListItem = styled.li`
    font-family: 'Barlow';
    margin: 1rem 0;
`

const FAQList = styled.ul`
  column-gap: 4rem;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-position: inside;
  list-style-type: none;
  margin-top: 2rem;
  padding: 0;
  @media(max-width: 499px) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
      text-align: center;
      padding-bottom: 3rem;
  }
`

const FAQListItemLI = styled.li`
  margin-bottom: 2rem;
  padding: 0 1rem;
  font-family: 'Barlow';
`

const FAQListItemQuestion = styled.div`
  font-family: 'Barlow';
  font-weight: bold;
  margin-bottom: 1rem;
`

const FAQListItemAnswerContainer = styled.div`
`

const FAQAnswerList = styled.ul`
  padding: 0;
  margin-top: 1rem;
`

const FAQAnswerListItem = styled.li`
  margin-bottom: 1rem;
`

const FAQAnswerListItemDescription = styled.span`
  font-weight: bold;
`

const FAQAnswerListItemSummary = styled.span`

`

const FAQListItemAnswer = (props) => {
    if (props.answerType === 'description-summary-list') {
        return (
            <FAQListItemAnswerContainer>
                <div>{props.answer[0]}</div>
                <FAQAnswerList>
                    {props.answer.slice(1).map(e =>
                        <FAQAnswerListItem>
                            <FAQAnswerListItemDescription>
                                {e.description}
                            </FAQAnswerListItemDescription>
                            <SeparatorSpan>|</SeparatorSpan>
                            <FAQAnswerListItemSummary>
                                {e.summary}
                            </FAQAnswerListItemSummary>
                        </FAQAnswerListItem>)
                    }
                </FAQAnswerList>
            </FAQListItemAnswerContainer>
        )
    }

    if (props.answerType === 'plain-list') {
        return (
            <FAQListItemAnswerContainer>
                <div>{props.answer[0]}</div>
                <FAQAnswerList>
                    {props.answer.slice(1).map(e =>
                        <FAQAnswerListItem>
                            <FAQAnswerListItemSummary>
                                {e}
                            </FAQAnswerListItemSummary>
                        </FAQAnswerListItem>)
                    }
                </FAQAnswerList>
            </FAQListItemAnswerContainer>
        )
    }

    if (props.answerType === 'has-link') {
        return (
            <FAQListItemAnswerContainer>
                {props.answer[0]}
                {props.answer[1]}
            </FAQListItemAnswerContainer>
        )
    }

    return (
        <FAQListItemAnswerContainer>
            {props.answer}
        </FAQListItemAnswerContainer>
    )
}

const FAQListItem = (props) =>
    <FAQListItemLI>
        <FAQListItemQuestion>
            {props.question}
        </FAQListItemQuestion>
        <FAQListItemAnswer answer={props.answer} answerType={props.answerType} />
    </FAQListItemLI>


const desktopSize = '34rem'
const mobileSize = '36rem'

// TODO clean up the unused components
const InnerTestimonialContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${desktopSize};
    width: 60%;
    @media(max-width: 499px) {
        width: 80%;
        height: 100%;
    }
`

const TestimonialsContainer = styled.div`
    margin: auto;
    min-height: ${desktopSize};
    display: flex;
    @media(max-width: 499px) {
        min-height: ${mobileSize};
    }
`

const TestimonialControlsContainer = styled.div`
    text-align: right;
`

const TestimonialControlsButton = styled.button`
    background: ${GRAY};
    border: none;
    outline: none;
    padding: 05.rem;
    color: ${WHITE};
    &:hover {
        cursor: pointer;
    }
    :disabled {
        color: ${GRAY};
    }
    @media(max-width: 499px) {
        padding: 0.5rem 0;
    }
`

const TestimonialsControls = (props) =>
    <TestimonialControlsContainer>
    </TestimonialControlsContainer>


const TestimonialDisplayDiv = styled.div`
    font-family: 'Barlow';
    display: flex;
    justify-content: space-between;
`

const TestimonialDisplayDivContent = styled.div`

`

const TestimonialDisplayDivPerson = styled.div`
    font-weight: bold;
    margin-top: 1rem;
`

const BackButtonDiv = styled.div`
    font-family: 'Barlow';
    display: flex;
    justify-content: flex-begin;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 2rem;
    @media(min-width: 499px) {
      width: 20%;
    }
    @media(max-width: 500px) {
      width: 10%;
    }
`

const ForwardButtonDiv = styled.div`
  font-family: 'Barlow';
  display: flex;
  justify-content: flex-end;
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  @media(min-width: 499px) {
    width: 20%;
  }
  @media(max-width: 500px) {
    width: 10%;
  }
`

export const TestimonialBack = (props)  =>
    <BackButtonDiv>
        <TestimonialControlsButton onClick={props.decrementer} >{"<"}</TestimonialControlsButton >
    </BackButtonDiv>

export const TestimonialForward = (props) =>
  <ForwardButtonDiv>
      <TestimonialControlsButton onClick={props.incrementer} >{">"}</TestimonialControlsButton>
  </ForwardButtonDiv>

const TestimonialDisplay = (props) =>
    <TestimonialDisplayDiv>
        <div style={{
            display: 'block',
            height: '100%'
        }}>
            <TestimonialDisplayDivContent>{props.testimonial.content}</TestimonialDisplayDivContent>
            <TestimonialDisplayDivPerson>
                {props.testimonial.person}
            </TestimonialDisplayDivPerson>
        </div>
    </TestimonialDisplayDiv>

const TestimonialActiveContainer = styled.div`
    text-align: center;
    margin-bottom: 1rem;
`

const TestimonialDotSpan = styled.span`
    margin-left: 0.25rem;
    @media(max-width: 499px) {
      margin: 0 -05.rem;
    }
`

const TestimonialDot = (props) =>
    <TestimonialDotSpan>{props.active ? <FaCircle size={10} /> : <FaRegCircle size={10} />}</TestimonialDotSpan>

const TestimonialActiveIcon = (props) =>
    <TestimonialActiveContainer>
        {
            Array.from(Array(props.dotCount)).map((x, i) =>
                <TestimonialControlsButton onClick={() => props.setTestimonialIndex(i)}>
                    <TestimonialDot active={i === props.activeIndex} />

                </TestimonialControlsButton>
            )
        }
    </TestimonialActiveContainer>




export {
    BulletedList,
    BulletedListItem,
    FAQList,
    FAQListItem,
    FullTextSection,
    GrayRow,
    GrayTextSection,
    ImageSection,
    ResizingImageSection,
    InnerTestimonialContainer,
    ListItemTitleDescription,
    PageHeadline,
    PlainListItem,
    Row,
    SectionHeadline,
    TestimonialActiveIcon,
    TestimonialsContainer,
    TestimonialsControls,
    TestimonialDisplay,
    UndecoratedList,
    WhiteTextSection,
}
