import React, { useEffect } from 'react';
import Navigation from './Navigation';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import {
  About,
  BookAHotel,
  Benefits,
  Contact,
  Home,
  HowItWorks,
} from './pages'

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const MainSite = () =>
  <>
    <ScrollToTop />
    <Navigation />
    <Route exact path="/about" component={About} />
    <Route exact path="/benefits" component={Benefits} />
    <Route exact path="/contact" component={Contact} />
    <Route exact path="/how-it-works" component={HowItWorks} />
    <Route exact path="/book-a-hotel" component={BookAHotel} />
  </>

const App = () => {
  async function onLoad() {
    const res = await fetch('https://girl-friday.herokuapp.com/page_views',
      {
        method: 'POST',
        body: new URLSearchParams(`user_agent=${navigator.userAgent}`)
      })

    console.log(res);
  }

  React.useEffect(() => {
    onLoad()
  }, []);

  return (
    <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route component={MainSite} />
        </Switch>
    </Router>
  )
}


export default App;
