import React from 'react';
import {
    ContainerMBM,
    GlobalChanges,
    ScrollToTopOnMount,
} from '../../components';
import {
    GrayRow,
    GrayTextSection,
    ResizingImageSection,
    ListItemTitleDescription,
    PageHeadline,
    Row,
    SectionHeadline,
    TestimonialBack,
    TestimonialsContainer,
    TestimonialForward,
    UndecoratedList,
} from "../components/atoms"
import PartnerListing from "./PartnerListing"
import Footer from '../../Footer';

import styled from 'styled-components';

const benefitsHeadline = "Elevate Your Travel Standards"
const whyChooseUsImage = require("./why-choose-us.jpg")

const whyChooseUsHeadling = "Why Choose Us?"
const whyChooseUsList = [
    {
        summary: "Extra Perks",
        description: "We have personal and contractual relationships with the best hotels and travel suppliers around the world. Our clients are always given the VIP treatment and frequently receive complimentary room upgrades and special amenities. KGTC perks are combinable with individual hotel & airline loyalty programs."
    },
    {
        summary: "Save Time",
        description: "There is an overwhelming amount of information on the internet, most of which is not even applicable to you, and oftentimes not accurate."
    },
    {
        summary: "Expertise",
        description: "We book travel all day, every day and have learned a thing or two along the way. We work with trusted partners around the globe that are experts in their particular destinations so we are always up to date on the latest and greatest. We are constantly exploring the most beautiful destinations so that we can offer firsthand suggestions and we receive continuous feedback from our clients."
    },
    {
        summary: "Access",
        description: "We open doors that are ordinarily closed. We can snag you that impossible restaurant reservation, get you after hours entry at museums, and so much more."
    },
    {
        summary: "Stress Free",
        description: "Working with us is a fun and collaborative process. When the unexpected happens abroad, we have your back and are an extra layer of protection."
    },
    {
        summary: "Destination Matchmaking",
        description: "We are not just experts on travel, we are experts on our clients. We are constantly learning about our client’s ever evolving sense of adventure, aesthetic tastes, culinary preferences, and travel standards so that we can suggest destinations perfectly tailored to their preferences."
    }
]

const WhatWeOffer = () =>
    <Row>

        <GrayTextSection side={'left-small'}>
            <SectionHeadline>
                {whyChooseUsHeadling}
            </SectionHeadline>
            <UndecoratedList>
                {
                    whyChooseUsList.map(e => <ListItemTitleDescription summary={e.summary} description={e.description} />)
                }
            </UndecoratedList>
        </GrayTextSection>

        <ResizingImageSection image={whyChooseUsImage} side={'right-large'} mobileHeight={'16rem'} />
    </Row>

// const testimonialsHeadline = "Testimonials"
const testimonialContent = [
    {
        content: "The best in the business! Kim truly listened to what we were looking for and the itinerary was so customized and thoughtful. We’ve worked with other travel agents and tour operators before, but nothing compares to the level of expertise KGTC brought to the table.",
        person: "Carla, Leisure Client"
    },
    {
        content: "We’ve always loved researching our own trips but decided to give up some control with so much wedding planning stress. Everywhere we went we were treated like royalty! The upgraded rooms were an ABSOLUTE DREAM and Kim even had framed photos from our wedding scattered around the room. We’re glad the wedding finally forced us to use a travel advisor because we’re never planning a trip without Kim ever again.",
        person: "Michael & Ashley, Honeymooners"
    },
    {
        content: "Our trip to Patagonia was incredible! When we got frustrated with online research we reached out to Kim. The next day she had a conference call set up with one of the top Patagonia guides to talk us through the different hike and experience options so we could decide on what stops to make. Kim then sent us the most detailed itinerary with everything we had asked for and so many things that we wouldn’t have thought of on our own. While traveling we were so well taken care of by the hotels and guides she set us up with.",
        person: "Taylor & Randall, Leisure Clients"
    }
];

const TestimonialsSlideshow = styled.div`
    border: 2px solid white;
    margin: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Barlow;
`

const TSSInnderDiv = styled.div`
    display: flex;
    margin: 0 2rem;
`

const TestimonialContent = styled.div`
    text-align: center;
    font-size: 1.9rem;
    max-width: 54rem;
    margin: auto;
    margin-bottom: 1rem;
    @media(max-width: 800px) {
        font-size: 4vw;
    }
  }
`
const TestimonialPerson = styled.div`
    margin-top: 1rem;
    text-align: center;
    font-size: 1.25rem;
`

const TSSContentDiv = styled.div`
    display: flex;
    flex-direction: column;
`


const Testimonials = () => {
    const [testimonialIndex, setTestimonialIndex] = React.useState(0)

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setTestimonialIndex((previous) => {
                return ((previous + 1) % testimonialContent.length)
            });
        }, 6000)

        return () => clearTimeout(timeout);
    }, [testimonialIndex, setTestimonialIndex])

    return (
        <GrayRow>
            <TestimonialsContainer>
                <TestimonialsSlideshow>
                    <TSSInnderDiv>
                            <TestimonialBack decrementer={() => testimonialIndex === 0 ? setTestimonialIndex(testimonialContent.length - 1) : setTestimonialIndex(testimonialIndex - 1)} />
                            <TSSContentDiv>
                                <TestimonialContent>{testimonialContent[testimonialIndex].content}</TestimonialContent>
                                <TestimonialPerson>{testimonialContent[testimonialIndex].person.toUpperCase()}</TestimonialPerson>
                            </TSSContentDiv>
                            <TestimonialForward incrementer={() => testimonialIndex === (testimonialContent.length - 1) ? setTestimonialIndex(0) : setTestimonialIndex(testimonialIndex + 1)} />
                    </TSSInnderDiv>
                </TestimonialsSlideshow>
            </TestimonialsContainer>
        </GrayRow>
    )
}

const Benefits = () =>
    <ContainerMBM>
        <GlobalChanges />
        <ScrollToTopOnMount />
        <PageHeadline>{benefitsHeadline}</PageHeadline>
        <WhatWeOffer />
        <Testimonials />
        <PartnerListing />
        <Footer />
    </ContainerMBM>

export default Benefits
