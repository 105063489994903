import React from "react";
import { Link } from 'react-router-dom'
import {
  ContainerMBM,
  GlobalChanges,
  ScrollToTopOnMount,
} from '../../components';
import {
  GrayTextSection,
  ImageSection,
  ListItemTitleDescription,
  PageHeadline,
  PlainListItem,
  Row,
  SectionHeadline,
  UndecoratedList,
  WhiteTextSection,
} from "../components/atoms"
import Footer from '../../Footer';

// TODO - Restore 'Itinerary Design' => 'Click Here' line
const whatWeOfferList = [
  {
    summary: "Itinerary Design",
    description: ["Custom itinerary design and bookings for all destinations. Click "," for our itinerary design process."],
    hasLink: <Link to="/how-it-works">here</Link>
  },
  {
    summary: "Hotel Matchmaking & Bookings",
    description: "Preferential rates, VIP perks, and insider knowledge."
  },
  {
    summary: "Group Room Blocks",
    description: "Site selection, contract negotiation, rooming list management, and optional on-site management."
  },
  {
    summary: "Air Bookings",
    description: "Exclusive discounted rates for business, first, and charters."
  },
  {
    summary: "Mobile Travel App",
    description: "Your personalized mobile app makes tracking day to day activities simple."
  },
  {
    summary: "On the Ground Support",
    description: "We have contacts on the ground around the globe ready to assist when the unexpected happens."
  },
  {
    summary: "VIP Entertainment Access",
    description: "Impossible to snag restaurant reservations, front row seats to sold-out concerts, tickets to the top sporting events, after hours museum tours, entry to red carpet events - our team has the network to secure preferred access around the world."
  }
]

const meetKGParagraphs = [
  "It was Eloise, the precocious little girl living at The Plaza Hotel, that first ignited my passion for hotels. Fast forward 15 years and I was moving into the iconic Waldorf=Astoria New York (The Plaza’s unofficial rival), hot on the heels of earning a Bachelor’s Degree in Hotel Management, and ready to start my new career.",
  "Working at the Waldorf gave me a true appreciation for the Art of Hospitality and was anything but ordinary. One day would be spent partnering with the Secret Service to secure hotel rooms for the President while another would be spent planning a last minute marriage proposal for a VIP. In 2013, rumors began to swirl that the Waldorf would be closing for renovations and I jumped ship to focus on producing events at The Metropolitan Museum of Art & The Guggenheim Museum. To get my hotel fix, I was planning getaways for my friends & family on the side and I began realizing there was a huge, seemingly untapped market for a modern day travel agency.",
  "In 2018, KGTC was born!"
]

const whatWeOfferHeadline = "Services"
const whatWeOfferImage = require("./images/what_we_offer.jpg")

const meetKGHeadline = "Meet KG"
const meetKGImage = require("./images/Kim_Headshot_2.jpg")

/**
 * End Dynamic Content Placeholder
 */

const WhatWeOffer = () =>
  <Row>
        <ImageSection image={whatWeOfferImage} side={'right-large'}  mobileHeight={'12rem'} />

    <WhiteTextSection side={'left-small'}>
      <SectionHeadline>
        {whatWeOfferHeadline}
      </SectionHeadline>
      <UndecoratedList>
        {
          whatWeOfferList.map(e => <ListItemTitleDescription summary={e.summary} description={e.description} hasLink={e.hasLink}/>)
        }
      </UndecoratedList>
    </WhiteTextSection>
  </Row>

const MeetKG = () =>
  <Row>
        <ImageSection image={meetKGImage} side={'left-extra-small'}  mobileHeight={'24rem'} />

    <GrayTextSection side={'right-extra-large'}>
      <SectionHeadline>
        {meetKGHeadline}
      </SectionHeadline>
      <UndecoratedList>
        {
          meetKGParagraphs.map(e => <PlainListItem>{e}</PlainListItem>)
        }
      </UndecoratedList>
    </GrayTextSection>
  </Row>

const aboutHeadline = 'Your Modern Day Travel Agency';

const About = () =>
  <ContainerMBM>
    <GlobalChanges />
    <ScrollToTopOnMount />
    <PageHeadline>{aboutHeadline}</PageHeadline>
    <WhatWeOffer />
    <MeetKG />
    <Footer />
  </ContainerMBM>

export default About;
