import React from 'react';
import styled from 'styled-components';

const FooterDiv = styled.div`
  text-align: center;
  width: 100%;
  margin: 2rem 0 2rem 0;
  font-family: 'Barlow';
`

const Email = styled.a`
  text-decoration: none;
  color: black;
`

const Insta = styled.a`
  text-decoration: none;
  color: black;
`

const Footer = () =>
  <FooterDiv>
    <Email href="mailto:info@kgtravelclub.com">info@kgtravelclub.com</Email>
    &nbsp;|&nbsp;
    <Insta target="_blank" href="https://instagram.com/kgtravelclub">@kgtravelclub</Insta>
  </FooterDiv>

export default Footer;
