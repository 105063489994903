import React from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import HomeNavigation from './HomeNavigation';

const GlobalChanges = createGlobalStyle`
  #root {
    overflow: hidden;
  }
`

const Logo = styled.img`
  display: none;
  @media (max-width: 480px) {
    display: initial;
    height: 200px;
    width: 200px;
    position: fixed;
    left: 50%;
    margin-left: -100px;
    margin-top: -9px;
  }
}
`

// const balloonsMobile = require('./backgrounds/balloonsmobile.jpg')
const penguins = require('./backgrounds/penguins.jpg')
const coastal_town = require('./backgrounds/coastal_town.jpg')
const whiteLogo = require('../../logos/logo_white_transparent.png')
const camelsMobile = require('./backgrounds/mobile/camels.jpg')
const elephant = require('./backgrounds/elephant.jpg')
const elephantMobile = require('./backgrounds/mobile/elephant.jpg')
const palms = require('./backgrounds/palms.jpg')
const india = require('./backgrounds/india.jpeg');

const CoverDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: black;
`


const TransitionStyles = createGlobalStyle`
.transparentimage {
  opacity: 0;
}
`

const BackgroundImage = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  min-height: 100vh;
  transition: opacity 1s ease-in-out;
  @media(max-width: 499px) {
    display: none;
  }
`

const TransitionImage = (props) =>
  <BackgroundImage src={props.src} className={`${props.className} ${props.transitionClass ? 'transparentimage' : ''}`} />

const BackgroundImageMobile = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  min-height: 100vh;
  transition: opacity 1s ease-in-out;
  display: none;
  @media(max-width: 499px) {
    display: initial;
  }
`

const TransitionImageMobile = (props) =>
  <BackgroundImageMobile src={props.src} className={`${props.className} ${props.transitionClass ? 'transparentimage' : ''}`} />

const orderedImages = [
  {
    image: coastal_town,
    key: 'coastal_town',
    next: 'elephant',
  },
  {
    image: elephant,
    key: 'elephant',
    mobileSrc: elephantMobile,
    next: 'balloons',
  },
  {
    image: palms,
    mobileSrc: palms,
    key: 'balloons',
    next: 'penguins',
  },
  {
    image: penguins,
    key: 'penguins',
    next: 'palace',
  },
  {
    image: india,
    mobileSrc: camelsMobile,
    key: 'palace',
    next: 'coastal_town',
  },
]


function Home() {
  const [loaded, setLoaded] = React.useState(false)
  const [imageNumber, setImageNumber] = React.useState(0);

  React.useEffect(() => {
    const myTimeout = setTimeout(() => {
      setImageNumber(1);
      setLoaded(true)
    }, 2000)

    return () => clearTimeout(myTimeout);
  },[])

  React.useEffect(() => {
    const myTimeout = setTimeout(() => {
      let newImageNumber = imageNumber + 1;
      console.log(`Updating image number from ${imageNumber} to ${newImageNumber}`)
      if (loaded) {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
          // THIS RELIES ON ONLY FOUR IMAGES
          if (newImageNumber === 4) {
          console.log('HELLO CHUCK - PASSED')
            newImageNumber = 0;
          }
        }

        setImageNumber(newImageNumber % orderedImages.length);
      }
    }, 4000)

    return () => clearTimeout(myTimeout)
  }, [loaded, imageNumber])

  const ImagesCollection = orderedImages.map((e,i) => <TransitionImage src={e.image} transitionClass={!(i === imageNumber)} />)
  const MobileImagesCollection = orderedImages.map((e,i) => <TransitionImageMobile src={e.mobileSrc ? e.mobileSrc : e.image} transitionClass={!(i === imageNumber)} />)

  return (
    <>
        <GlobalChanges />
        <TransitionStyles />
        <CoverDiv />
        {ImagesCollection}
        {MobileImagesCollection}
        <HomeNavigation />
        <Logo src={whiteLogo}/>
    </>
  );
}

export default Home;
