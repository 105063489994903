import React from 'react';
import styled from 'styled-components';

/**
 * TODO
 * 
 * We can make this into a JSON as well
 */
const belmondBelliniClub = require('./logos/belmond_bellini_club.png')
const fourSeasonsPreferred = require('./logos/Four-Seasons-preferred.png')
const mandarinOrientalFanClub = require('./logos/mandarin-oriental-fan.png')
const mandarinOrientalFanClubMobile =  require('./logos/mandarin-oriental-fan-small.png')
const relaisAndChate = require('./logos/relaisandchate.jpeg')
// const ritzCarltonStars = require('./logos/ritz-carlton-stars-program.png')
const rosewoodElite = require('./logos/rosewood-500_bw.jpg')
// const starwoodLuxury = require('./logos/starwood.png')
// const starwoodLuxuryMobile =  require('./logos/starwood_mobile.png')
const penClub = require('./logos/pen-club.jpg')
const virtuoso = require('./logos/vitruoso_logo.jpg')
const dorchester = require('./logos/dorchester.png')
const jumeirah = require('./logos/jumeirah.jpeg')
const oetker = require('./logos/oetker.jpeg')
const hyattPrive = require('./logos/hyatt-prive.png')
const ihgLuxury = require('./logos/ihg-luxury-life.png')
const coutureLangham = require('./logos/couture_langham.jpg')
const marriotStars = require('./logos/marriot-stars.png')
const luminous = require('./logos/luminous.png')
const roccoForte = require('./logos/rocco_forte.jpeg')
const luxuryCircle = require('./logos/luxury-circle.png')
const luxuryCircleMobile = require('./logos/luxury copy.png')
const smallLuxury = require('./logos/small_luxury.png')
// const selectMember = require('./logos/selectmember.png')

const HotelCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%
  margin: 0 1rem 0 1rem;
  flex: 1;
  @media(max-width: 499px) {
    width: 100%;
  }
`

const HotelImage = styled.img`
  width: 100%;
  height: 12rem;
  object-fit: contain;
  @media(max-width: 499px) {
    display: none;
  }
`

const MobileHotelImage = styled.img`
  display: none;
  height: 12rem;
  object-fit: contain;
  @media(max-width: 499px) {
    display: initial;
    width: 80%;
    margin-left: 10%;
  }
`

const HotelTitle = styled.div`
  text-align: center;
  padding: 1rem 0;
  font-family: 'Cormorant-Garamond';
  font-size: 1.5rem;
`

const HotelDescription = styled.div`
  font-family: Barlow;
  padding: 0 1rem 0 1rem;
`

const HotelRow = styled.div`
  display: flex;
  flex-direction: row;

  margin: 2rem 0 4rem 0;
  justify-content: center;
  @media(max-width: 499px) {
    flex-direction: column;
    margin: 0;
  }
`

const HotelsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const HotelsSizeGrid = styled.div`
  margin-top: 2rem;
  width: 100%;
  max-width: 1024px;
  @media(max-width: 499px) {
   margin-top: 0;
  }
`

const HotelsHeadline = styled.div`
  text-align: center;
  font-family: 'Cormorant-Garamond';
  font-size: 3rem;
  padding: 1rem;
`

const Hotel = (props) =>
  <HotelCont>
    <HotelImage src={props.hotelImage} />
    <MobileHotelImage src={props.mobileHotelImage === undefined ? props.hotelImage : props.mobileHotelImage} />
    <HotelTitle>{props.hotelName}</HotelTitle>
    <HotelDescription>{props.hotelDescription}</HotelDescription>
  </HotelCont>



const PartnerListing = () =>
    <HotelsContainer>
      <HotelsSizeGrid>
        <HotelsHeadline id="partner-listing">
          A few of our Partners & Perks
        </HotelsHeadline>
        <HotelRow>
          <Hotel
            hotelName='The Four Seasons Preferred Partner'
            hotelDescription="Exclusive invitation-only network. Client benefits include complimentary breakfast, $100 hotel credit, wifi, priority upgrades & wait-list clearance, customized VIP amenities, and exclusive rates & promotions globally.  "
            hotelImage={fourSeasonsPreferred}
          />
          <Hotel
            hotelName="Marriott International STARS"
            hotelDescription="When you book a hotel participating in the Marriott International STARS program with KG Travel Club, you become a privileged VIP guest. STARS brands include the Ritz-Carlton, EDITION, St.Regis, The Luxury Collection, and Bvlgari. Perks include $100 hotel credit, daily breakfast, welcome amenity, upgrades, and exclusive rate offers."
            hotelImage={marriotStars}
          />
          <Hotel
            hotelName="Marriott International Luminous"
            hotelDescription="When you book a hotel participating in the Marriott International Luminous program with KG Travel Club, you become a privileged VIP guest. Luminous brands include the JW Marriot, W Hotels, and the Autograph Collection plus participating hotels with Westin, Marriott Hotels, Renaissance, Le Meridien, and Tribute Portfolio. Perks include $100 hotel credit, daily breakfast, welcome amenity, upgrades, and exclusive rate offers."
            hotelImage={luminous}
          />
        </HotelRow>
        <HotelRow>
          <Hotel
            hotelName="Hyatt Privé"
            hotelDescription="As a member of Hyatt Privé clients are provided value-added benefits such as priority upgrades, daily breakfast, welcome amenities, $50-$100 hotel credit, early check-in & late check-out, and priority for connecting rooms."
            hotelImage={hyattPrive}
          />
          <Hotel
            hotelName='Belmond Bellini Club'
            hotelDescription='Membership in this prestigious programme ensures clients enjoy priority upgrades, unique amenities, value-added rates, and VIP upgrades at all Belmond properties worldwide.'
            hotelImage={belmondBelliniClub}
          />
          <Hotel
            hotelName='Rosewood Elite'
            hotelDescription="As a member of this exclusive program, clients receive a VIP welcome, priority upgrades, and other perks such as food and beverage or spa credits, complimentary breakfast daily, and free wifi."
            hotelImage={rosewoodElite}
          />
        </HotelRow>
        <HotelRow>

          <Hotel
            hotelName='Relais & Chateaux Preferred'
            hotelDescription='As one of only 25 member agencies in this exclusive partner program, clients receive complimentary breakfast, priority upgrades, and VIP amenities at more than 150 Relais & Chateaux properties worldwide.'
            hotelImage={relaisAndChate}
          />
          <Hotel
            hotelName='Mandarin Oriental Fan Club'
            hotelDescription="A privilege given only to a select few agencies worldwide, membership in the prestigious Mandarin Oriental Fan Club program ensures access to special benefits, such as upgrades, daily breakfast for two, complimentary wifi, and choice of $100 food & beverage or spa credit."
            hotelImage={mandarinOrientalFanClub}
            mobileHotelImage={mandarinOrientalFanClubMobile}
          />
          <Hotel
            hotelName='The Peninsula Club'
            hotelDescription="Membership in the invitation-only Peninsula PenClub clients staying at any peninsula hotel will receive priority upgrades, daily breakfast, flexible check-in, upgraded in-room amenities, and a complimentary 30-minute extension on any spa treatment."
            hotelImage={penClub}
          />
        </HotelRow>

        <HotelRow>
          <Hotel
            hotelName="Small Luxury Hotels of the World withIN Program"
            hotelDescription="There are 200 small luxury hotels around the world currently participating in this elite program. Benefits include daily breakfast, upgrades, hotel credits, early check-in & late check-out, etc."
            hotelImage={smallLuxury}
          />
          <Hotel
            hotelName="IHG Luxury Lifestyle Program"
            hotelDescription="Bespoke, benefits based program for select travel advisors booking Intercontinental, Kimpton, and Indigo Hotels. Luxury Lifestyle bookings receive guaranteed 4pm checkout, room upgrades, daily breakfast, $100 value added amenity, welcome amenity, priority waitlist, etc."
            hotelImage={ihgLuxury}
          />
          <Hotel
            hotelName='Dorchester Diamond Club'
            hotelDescription='As a member of the Dorchester Diamond Club clients are provided incredible value-added benefits such as guaranteed upgrades, daily full breakfast, and food & beverage or spa credits when staying at any Dorchester Collection hotel.'
            hotelImage={dorchester}
          />
        </HotelRow>

        <HotelRow>
          <Hotel
            hotelName='Jumeirah Passport to Luxury'
            hotelDescription="Jumeirah's invitation-only Passport to Luxury program provides exclusive amenities to clients; offers include daily breakfast for two, room upgrades, $100 food & beverage or spa credit, complimentary wifi, and early check-in & late check-out."
            hotelImage={jumeirah}
          />
          <Hotel
            hotelName="Shangri-La Luxury Circle"
            hotelDescription="As a KG Travel Club client, you can access exclusive benefits and amenities at Shangri-La hotels and resorts around the world. Benefits include upgrades, hotel credits, daily breakfast, a welcome amenity, early check-in & late check-out, etc."
            hotelImage={luxuryCircle}
            mobileHotelImage={luxuryCircleMobile}
          />
          <Hotel
            hotelName='Oetker Collection Pearl Partner'
            hotelDescription="As a member of Oetker Collection's Pearl Partner program, clients have exclusive access to a host of amenities and benefits such as upgrades at time of booking, allocation of the best room in category, up to $200 resort credit, priority access to courtesy car, daily complimentary breakfast, and more."
            hotelImage={oetker}
          />
        </HotelRow>
        <HotelRow>
          <Hotel
            hotelName="Couture by Langham"
            hotelDescription="An exclusive, by-invitation only partnership program. As a prestigious member of Couture, our clients are entitled to a range of benefits including special rates (up to 75% off), upgrades, food & beverage credit, early check-in & late check-out, welcome amenity, etc."
            hotelImage={coutureLangham}
          />
          <Hotel
            hotelName="Rocco Forte - Sir Rocco’s Knights"
            hotelDescription="Invitation only program offering benefits at Rocco Forte’s hotels. Benefits include exclusive rates, upgrades, welcome amenity, 20% spa discounts, hotel credits, early check-in & late check-out, daily breakfast, priority waitlist, etc."
            hotelImage={roccoForte}
          />
          <Hotel
            hotelName='Virtuoso'
            hotelDescription="Access to a portfolio of nearly 1,700 preferred partners - top hotels, cruise lines, tour operators, and more. Through these global connections we are able to curate the most memorable experiences for clients while providing exclusive rates, benefits and amenities."
            hotelImage={virtuoso}
          />
        </HotelRow>

      </HotelsSizeGrid>
    </HotelsContainer>

export default PartnerListing;
