import React from 'react';
import { ContainerMBM } from '../../components';
import { GrayRow, PageHeadline } from '../components/atoms';
import styled from 'styled-components';
import Footer from '../../Footer';

const AFFILIATE_ADDRESS = 'https://www.virtuoso.com/advisor/kimgreu43655';

const StyledPageHeadline = styled(PageHeadline)`
  @media(max-width: 499px) {
    padding: 1rem 0;
  }
`;

const DividerOuter = styled.div`
    display: flex;
    height: 2px;
`
const DividerPlaceHolder = styled.div`
    flex: 1;
    @media(max-width: 499px) {
      flex: 0;
    }
`;

const DividerColored = styled.div`
    height: 100%;
    background-color: #737373;
    flex: 3;
    @media(max-width: 499px) {
      flex: 1;
    }
`;

const DividerLine = () =>
    <DividerOuter>
        <DividerPlaceHolder />
        <DividerColored />
    </DividerOuter>

const IntroText = styled.div`
    padding: 1.5rem;
    font-size: 1.5rem;
    text-align: center;
    @media(max-width: 499px) {
      padding: 1.25rem;
      font-size: 1.25rem;
      text-align: center;
    }
`;

const SpacingRow = styled.div`
    height: 2rem;
    @media(max-width: 499px) {
      display: none;
    }
`

const OtherSpacingRow = styled.div`
    height: 0rem;
    @media(max-width: 499px) {
      height: 1rem;
    }
`

const ContentWrapper = styled.div`
    max-width: 64rem;
    margin: 2rem auto;
    @media(max-width: 499px) {
      margin: 0 auto;
    }
`;

    // padding: 1.5rem;
const CenteredListTitle = styled.div`
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    font-size: 1.75rem;
    text-align: center;
`;

const CenteredListItem = styled.div`
    padding: 0.25rem;
    font-size: 1.25rem;
    text-align: center;
`;

const ResizeMobileCenteredListTitle = styled(CenteredListTitle)`
  @media(max-width: 499px) {
    font-size: 1.3rem;
  }
`;

const ResizeMobileCenteredListItem = styled(CenteredListItem)`
  @media(max-width: 499px) {
    font-size: 1rem;
  }
`;

const BookAHotelLinkDiv = styled.div`
    text-align: center;
    height: 5rem;
    display: flex;
    justify-content: center;
    margin: 3rem 0;
`;

const BookAHotelLinkStyled = styled.a`
    text-decoration: none;
    padding: 2rem;
    background-color: black;
    color: white;
`;

const SideBySideListRow = styled.div`
    display: flex;
    padding: 1rem;
    @media(max-width: 499px) {
      flex-direction: column;
    }
`;

const SideBySideItemTitle = styled.div`
    flex: 1;
    font-size: 1.3rem;
    padding: 1rem 0;
    @media(max-width: 499px) {
      text-align: center;
    }
`;

const SideBySideItemConent = styled.div`
    flex: 3;
    @media(max-width: 499px) {
      flex: 1;
    }
`;

const HowToPar = styled.div`
    ${({ makeItalic }) => makeItalic ? 'font-style: italic;' : ''}
    padding: 1rem 0;
`;

const BookAHotelLink = () =>
    <BookAHotelLinkDiv>
        <BookAHotelLinkStyled href={AFFILIATE_ADDRESS} target="_blank">BOOK A HOTEL</BookAHotelLinkStyled>
    </BookAHotelLinkDiv>

const HowToItemParagraph = ({ bodyText, makeItalic }) => {
    return (
        <HowToPar makeItalic={makeItalic}>{bodyText}</HowToPar>
    );
}

const HowToItem = ({ htTitle, htContent }) => {
    /**
     * TODO this thing will always make the 3rd item italic
     */
    return (
        <SideBySideListRow>
            <SideBySideItemTitle>{htTitle}</SideBySideItemTitle>
            <SideBySideItemConent>{
                htContent.map((it, i) => (
                    <HowToItemParagraph bodyText={it} makeItalic={i === 2}/>
                ))
            }</SideBySideItemConent>
        </SideBySideListRow>
    );
}

export const BookAHotel = () => {
    return (
        <ContainerMBM>
            <SpacingRow />
            <StyledPageHeadline>Book yourself with VIP perks!</StyledPageHeadline>
            <SpacingRow />
            <GrayRow>
                <ContentWrapper>
                    <IntroText>
                        KG Travel Club clients receive exclusive perks with their hotel bookings through our partnership with Virtuoso. If you already know where you want to go and do not require our hotel matchmaking service, you can now make your own hotel reservations using the Virtuoso Hotel Booking Tool.
                    </IntroText>
                    <CenteredListTitle>
                        Common Virtuoso Perks Include:
                    </CenteredListTitle>
                    <CenteredListItem>
                        Complimentary Daily Breakfast
                    </CenteredListItem>
                    <CenteredListItem>
                        $100+ Hotel Credit
                    </CenteredListItem>
                    <CenteredListItem>
                        Priority for a Room Upgrade
                    </CenteredListItem>
                    <CenteredListItem>
                        Early Check-in & Check-Out, if available
                    </CenteredListItem>
                    <BookAHotelLink />
                    <OtherSpacingRow />
                </ContentWrapper>
            </GrayRow>
            <SpacingRow />
            <StyledPageHeadline>How to use the Virtuoso Hotel Booking Portal</StyledPageHeadline>
            <ContentWrapper>
                <HowToItem
                    htTitle="Search & Select Hotel"
                    htContent={["Click the “BOOK A HOTEL” button above which will open KG Travel Club’s custom Virtuoso Hotel Booking portal. Navigate to the “Hotels” section at the top of the page and enter your destination in the search bar. Then click on the hotel you want to book. Once on the hotel’s page, click the red “Check Rates and Availability” button. Here you can enter your travel dates and browse available room categories and rates. Once you’ve selected your room category click the red “Book” button."]}
                />
                <DividerLine />
                <HowToItem
                    htTitle="Create an Account & Confirm Booking"
                    htContent={["If this is your first time using the Virtuoso Hotel Booking portal you will be prompted to create an account. If it does not automatically assign me as your advisor you will need to search my name and confirm “Kim Greulich” as your advisor.", "Otherwise if you already have an account, you can proceed with your payment information. Once you’ve finished confirming the booking you will receive an email from Virtuoso with the details.", "Important: If this is your first time using the portal, you must create a Virtuoso account from a desktop or laptop computer (not phone)."]}
                />
                <DividerLine />
                <HowToItem
                    htTitle="Finalize Details"
                    htContent={["KG Travel Club will also reach out to you to reconfirm your stay and request any additional information needed. We will follow up with the hotel to ensure all special requests, Virtuoso amenities, and VIP status are added to your stay!"]}
                />
            </ContentWrapper>
            <GrayRow>
                <ContentWrapper>
                    <StyledPageHeadline>FAQ & Helpful Tips</StyledPageHeadline>
                    <ResizeMobileCenteredListTitle>
    When should I use the Virtuoso Hotel Booking Portal and when should I not?
                    </ResizeMobileCenteredListTitle>
                    <ResizeMobileCenteredListItem>
    If you already know the hotel and room category you want to book, the Virtuoso Hotel Booking Portal is a great tool to make an easy hotel reservation with perks. If you need us to match you to a hotel or are looking for more detailed itinerary planning then you should reach out to us directly.
                    </ResizeMobileCenteredListItem>
                    <ResizeMobileCenteredListTitle>
    What are the benefits of booking through the Virtuoso Hotel Booking Portal? 
                    </ResizeMobileCenteredListTitle>
                    <ResizeMobileCenteredListItem>
    Booking hotels through the Virtuoso Hotel Booking Portal typically earns you Virtuoso Perks (which often include daily breakfast, a $100 hotel credit, priority for a room upgrade, and early check in/late check out, if available). 
                    </ResizeMobileCenteredListItem>
                    <ResizeMobileCenteredListTitle>
    Why won’t it allow me to book Four Seasons through the Virtuoso Hotel Booking portal?
                    </ResizeMobileCenteredListTitle>
                    <ResizeMobileCenteredListItem>
    Four Seasons hotels are not available for booking through the Virtuoso Hotel Booking portal. However, KGTC is a Four Seasons Preferred Partner which means you should always book Four Seasons through us! Reach out to us directly to book your Four Seasons reservation.
                    </ResizeMobileCenteredListItem>
                    <ResizeMobileCenteredListTitle>
    What if I can’t find the hotel I want to book on the Virtuoso Hotel Booking portal?
                    </ResizeMobileCenteredListTitle>
                    <ResizeMobileCenteredListItem>
The Virtuoso Hotel Booking Portal is only available for Virtuoso member hotels. If you would like to book another hotel that you don’t see listed, please reach out to us directly. We have many other VIP hotel affiliations beyond Virtuoso. Click <a href="/benefits#partner-listing">here</a> for some of our other partners & perks.
                    </ResizeMobileCenteredListItem>
                    <BookAHotelLink />
                    <OtherSpacingRow />
                </ContentWrapper>
            </GrayRow>
            <Footer />
        </ContainerMBM>
    )
}
