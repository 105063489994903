import React, { useState } from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import {
  ContactImageDiv,
  Container,
  CoverImage,
  Detail,
  FlexContainerContainerContact,
  FlexContainerLeftTextContact,
  GrayWordsContainer,
  Label,
  ScrollToTopOnMount,
  SectionSubHeading,
  SmallPageHeadlineTop,
} from '../../components';
import Footer from '../../Footer';
import { useForm } from "react-hook-form";

const contactImage = require('./contactPlaneImage.jpg');

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  text-align: left;
  padding: 12rem 2rem 2rem 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  width: 100%;
  overflow: hidden;
  @media(max-width: 499px) {
    padding: 8rem 2rem 2rem 0;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: center;
    font-family: Barlow;

    @media (max-width: 480px) {
      font-size: 1.5rem;
    }

    &:hover {
      color: #d3d3d3;
    }
  }
`;


const MessageDiv = styled.div`
  text-align: center;
  font-family: Barlow;
  margin: 4rem;
  font-size: 4rem;
  color: white;
  @media (max-width: 480px) {
    font-size: 3rem;
  }
`

// TODO refactor submiting name
const Confirmation = ({open, close, submitting}) => {
  const text = !submitting ?
    'Submitting...'
    :
    'We’ll be in touch shortly to start planning your dream trip.'

  return (
    <StyledMenu open={open} onClick={() => close()}>
      <MessageDiv>
        {text}
      </MessageDiv>
    </StyledMenu>
  )
}

const NewGlobals = createGlobalStyle`
  html {
    margin: 0 auto;
    position: relative;
  }

  body {
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    display: flex;
    height: 100%;
    flex-direction: column;
    position: absolute;
    width: 100%;
    overflow-y: scroll;
    overflow: scroll;
    box-sizing: border-box;
  }
`

const Input = styled.input`
  width: 100%;
  padding: 0.5rem 0 0.5rem 0;
  border: 1px solid #737373;
  box-sizing: border-box;
`

const BigInput = styled.textarea`
  width: 100%;
  padding: 0.5rem 0 0.5rem 0;
  border: 1px solid #737373;
  box-sizing: border-box;
`

const Button = styled.button`
  font-family: 'Cormorant Garamond';
  font-size: 1.5rem;
  padding: 1rem 2rem 1rem 2rem;
  color: white;
  background-color: black;
  font-weight: bold;
  :hover {
    cursor: pointer;
  }
  border-radius: 5px;
`

const SubmitButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`


const SubmitButton = (props) =>
  <SubmitButtonDiv>
    <Button onClick={props.submitForm}>
      SUBMIT
    </Button>
  </SubmitButtonDiv>



const Form = () => {
  const { register, handleSubmit, formState: { isSubmitting} } = useForm()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const onSubmit = async (message) => {
    await new Promise((resolve, reject) => setTimeout(() => resolve(), 100))
    await fetch('https://submit-form.com/WaPFtQb0',
      {
        method: 'POST',
        body: JSON.stringify({ message }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })

    setShowConfirmation(true)
  }

  return (
    <>
    <FlexContainerContainerContact>
      <Confirmation open={showConfirmation || isSubmitting} close={() => setShowConfirmation(false)} submitting={showConfirmation}/>
      <FlexContainerLeftTextContact>
        <ContactImageDiv>
          <CoverImage src={contactImage} />
        </ContactImageDiv>
        <GrayWordsContainer>
          <SectionSubHeading>&nbsp;</SectionSubHeading>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Detail>
              <Label>
                Name
              </Label>
            <Input {...register("name")} required={true} />
          </Detail>
          <Detail>
              <Label>
                Email
              </Label>
            <Input {...register("email")} required={true} />
          </Detail>
          <Detail>
              <Label>
                Proposed Destination(s)
              </Label>
            <Input {...register("destinations")} required={true} />
          </Detail>
          <Detail>
              <Label>
                Number of Travelers
              </Label>
              <Input {...register("travelers")} required={true} />
          </Detail>
          <Detail>
              <Label>
                Celebrating something special?
              </Label>
            <Input {...register("celebrating")} required={true} />
          </Detail>
          <Detail>
              <Label>
                Travel Dates
              </Label>
            <Input {...register("dates")} required={true} />
          </Detail>
          <Detail>
              <Label>
                Departure Airport
              </Label>
            <Input {...register("departing")} required={true} />
          </Detail>
          <Detail>
              <Label>
                Estimated Budget
              </Label>
            <Input {...register("budget")} required={true} />
          </Detail>
          <Detail>
              <Label>
                Tell Us More About Your Ideal Trip
              </Label>
            <BigInput rows="3" {...register("tellMeMore")} required={true} />
          </Detail>
              <SubmitButton />
          </form>
        </GrayWordsContainer>
      </FlexContainerLeftTextContact>
    </FlexContainerContainerContact>
      <Footer />
    </>
  );
}

const MobileMarginBottomChange = styled.div`
  @media(max-width: 499px) {
    margin-bottom: -12rem;
  }
`

const Contact = () =>
  <MobileMarginBottomChange>
  <Container>
    <ScrollToTopOnMount />
    <NewGlobals />
    <SmallPageHeadlineTop>
      Your Journey Starts Here..
    </SmallPageHeadlineTop>
    <Form />
  </Container>
  </MobileMarginBottomChange>

export default Contact;
